import { Cart } from 'types/Cart';
import { TEmarsysCartItem, TEmarsysEvent } from 'types/Emarsys';
import { Order } from 'types/Order';
import { log } from './loggerUtil';

export const pushEmarsysEvent = (event: TEmarsysEvent) => {
  log('emarsysUtil', 'pushEmarsysEvent', event);
  if (typeof window !== 'undefined' && !!window.ScarabQueue) {
    ScarabQueue.push(event);
  }
};

export const getEmarsysCartItemsData = (cart?: Cart) => {
  if (!cart || !cart.entries) return [];

  const itemMap: { [key: string]: TEmarsysCartItem } = {};

  cart?.entries.forEach((entry) => {
    const itemCode = entry?.product?.code ?? '';
    const itemPrice = entry?.totalPrice?.value ?? 0;
    const itemQuantity = entry?.quantity ?? 0;

    if (itemMap?.[itemCode]) {
      itemMap[itemCode].quantity += itemQuantity;
      itemMap[itemCode].price += itemPrice;
    } else {
      itemMap[itemCode] = { item: itemCode, price: itemPrice, quantity: itemQuantity };
    }
  });

  return Object.values(itemMap);
};

export const getEmarsysOrderItemsData = (order: Order) => {
  const orderDeliveryCost = order?.deliveryCost?.value ?? 0;
  const orderTotalDiscounts = order?.totalDiscounts?.value ?? 0;
  const firstEntryPrice = order?.entries?.[0]?.totalPrice?.value ?? 0;
  const firstItemTotalPrice = firstEntryPrice + orderDeliveryCost - orderTotalDiscounts;

  const items = order?.entries?.map((entry, index) => ({
    item: entry.product?.code ?? '',
    price: (index === 0 ? firstItemTotalPrice : entry?.totalPrice?.value) ?? 0,
    quantity: entry?.quantity ?? 0,
  }));

  return items ?? [];
};
