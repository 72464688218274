'use client';

import useEmarsys from 'hooks/useEmarsys';
import usePrevious from 'hooks/usePrevious';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { TEmarsysCartItem } from 'types/Emarsys';
import { Order } from 'types/Order';
import { getEmarsysOrderItemsData, pushEmarsysEvent } from 'utils/emarsysUtil';

type Props = {
  cartData: TEmarsysCartItem[];
  customerId: string;
  order: Order;
};

const EmarsysEventPurchase = ({ cartData, customerId, order }: Props) => {
  const orderItems = getEmarsysOrderItemsData(order);
  const orderData = { items: orderItems, orderId: order.code };

  const pathname = usePathname();
  const prevPathname = usePrevious(pathname);

  useEffect(() => {
    if (prevPathname === pathname) return;
    pushEmarsysEvent(['setCustomerId', customerId]);
    pushEmarsysEvent(['cart', cartData]);
    pushEmarsysEvent(['purchase', orderData]);
    pushEmarsysEvent(['go']);
  }, [customerId, cartData, order, prevPathname, pathname]);

  return null;
};

export default EmarsysEventPurchase;
