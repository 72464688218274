import AppBanner from 'components/AppBanner/AppBanner';
import { useWebConfig } from 'features/configuration/queries';
import useRouter from 'hooks/useRouter';
import { useSelector } from 'hooks/useSelector';
import dynamic from 'next/dynamic';
import { useEffect, useRef, useState } from 'react';
import AnnouncementBar from '../Layout/AnnouncementBar/AnnouncementBar';
import { StyledBackdrop, StyledEmptyHeaderBar, StyledHeader } from './Header.styled';
import MainBar from './MainBar/MainBar';
import SuggestionsBar from './MainBar/SuggestionsBar/SuggestionsBar';
import TopBar from './TopBar/TopBar';
const MobileMenu = dynamic(() => import('./MobileMenu/MobileMenu'));

interface HeaderProps {
  headerVisible?: boolean;
  hideAccount?: boolean;
  hideLanguageSwitcher?: boolean;
  inStore?: boolean;
  minimal?: boolean;
  showEmptyBanner?: boolean;
}

const Header = ({
  headerVisible = true,
  hideAccount,
  hideLanguageSwitcher,
  inStore,
  minimal,
  showEmptyBanner,
}: HeaderProps) => {
  const { locale } = useRouter();

  const topBarRef = useRef<HTMLDivElement>(null);

  const [showAccountDropDown, setShowAccountDropDown] = useState(false);
  const [showCategoryDropDown, setShowCategoryDropDown] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [fixedHeader, setFixedHeader] = useState(false);

  const { data: webConfig } = useWebConfig();

  const cart = useSelector((state) => state.cart.cart);

  const toggleAccountDropDown = () => {
    setShowAccountDropDown(!showAccountDropDown);
  };

  const setFixedNav = () => {
    const scrollOffset = topBarRef?.current?.offsetTop || 0;

    if (window.pageYOffset > scrollOffset) {
      setFixedHeader(true);
    } else {
      setFixedHeader(false);
    }
  };

  const toggleCategoryDropDown = () => {
    setShowCategoryDropDown(!showCategoryDropDown);
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', setFixedNav, { passive: true });
    }

    return () => {
      if (typeof window !== 'undefined') {
        //@ts-ignore
        window.removeEventListener('scroll', setFixedNav, { passive: true });
      }
    };
  }, [locale]);

  if (!headerVisible) {
    return null;
  }

  return (
    <>
      {!minimal && webConfig?.announcements && <AnnouncementBar announcements={webConfig?.announcements} />}
      {!minimal && <TopBar ref={topBarRef} />}

      <StyledHeader fixed={fixedHeader} id="header" className="z-header">
        <StyledBackdrop onClick={() => toggleCategoryDropDown()} show={showCategoryDropDown || showAccountDropDown}>
          <MainBar
            cart={cart}
            hideAccount={!!hideAccount}
            hideLanguageSwitcher={!!hideLanguageSwitcher}
            inStore={!!inStore}
            minimal={!!minimal}
            showAccountDropDown={showAccountDropDown}
            showCategoryDropDown={showCategoryDropDown}
            toggleAccountDropDown={toggleAccountDropDown}
            toggleCategoryDropDown={toggleCategoryDropDown}
            toggleMobileMenu={toggleMobileMenu}
          />
        </StyledBackdrop>
        {!minimal && <SuggestionsBar className="hidden md:flex" />}
        {minimal && showEmptyBanner && <StyledEmptyHeaderBar />}
      </StyledHeader>

      {!minimal && <MobileMenu toggleMobileMenu={toggleMobileMenu} visible={showMobileMenu} />}
    </>
  );
};

export default Header;
