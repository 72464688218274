'use client';

import usePrevious from 'hooks/usePrevious';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { TEmarsysCartItem } from 'types/Emarsys';
import { pushEmarsysEvent } from 'utils/emarsysUtil';

type Props = {
  cartData: TEmarsysCartItem[];
  categoryCode: string;
  customerId: string;
};

const EmarsysEventCategory = ({ cartData, categoryCode, customerId }: Props) => {
  const pathname = usePathname();
  const prevPathname = usePrevious(pathname);

  useEffect(() => {
    if (prevPathname === pathname) return;
    pushEmarsysEvent(['setCustomerId', customerId]);
    pushEmarsysEvent(['cart', cartData]);
    pushEmarsysEvent(['category', categoryCode]);
    pushEmarsysEvent(['go']);
  }, [categoryCode, customerId, cartData, pathname, prevPathname]);

  return null;
};

export default EmarsysEventCategory;
