import useAuthResolver from 'hooks/useAuthResolver';
import useCart from 'hooks/useCart';
import { TEmarsysData } from 'types/Emarsys';
import { getEmarsysCartItemsData } from 'utils/emarsysUtil';
import EmarsysEventCategory from './EmarsysEventCategory';
import EmarsysEventGeneral from './EmarsysEventGeneral';
import EmarsysEventPurchase from './EmarsysEventPurchase';
import EmarsysEventView from './EmarsysEventView';

const EmarsysEvents = ({ event }: { event: TEmarsysData }) => {
  const { anonymous, authResolved, user } = useAuthResolver();
  const { anonymousCartId, cart, isFetchingCart, isInitializedCart, isUpdatingCart } = useCart();

  const isCartReady = isInitializedCart && !isFetchingCart && !isUpdatingCart;

  if (!authResolved || (!anonymous && !isCartReady) || (anonymous && anonymousCartId && !isCartReady)) {
    return null;
  }

  const cartData = getEmarsysCartItemsData(cart);
  const id = user?.customerId ?? 'anonymous';

  switch (event.name) {
    case 'general': {
      return <EmarsysEventGeneral cartData={cartData} customerId={id} />;
    }
    case 'view': {
      return <EmarsysEventView productCode={event.item} cartData={cartData} customerId={id} />;
    }
    case 'category': {
      return <EmarsysEventCategory categoryCode={event.categoryCode} cartData={cartData} customerId={id} />;
    }
    case 'purchase': {
      return <EmarsysEventPurchase order={event.order} cartData={cartData} customerId={id} />;
    }
    default: {
      return <EmarsysEventGeneral cartData={cartData} customerId={id} />;
    }
  }
};

const EmarsysTracking = ({ event }: { event: TEmarsysData }) => {
  const merchantId = process.env.NEXT_PUBLIC_EMARSYS_MERCHANT_ID;

  if (!merchantId) {
    return null;
  }

  return <EmarsysEvents event={event} />;
};

export default EmarsysTracking;
