import { useSearchSuggestions } from 'features/search/queries';
import useRouter from 'hooks/useRouter';
import { Ref, RefObject, forwardRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useTypewriter } from 'react-simple-typewriter';
import { Button, IconButton } from 'ui/Button/Button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'ui/Form/Form';
import { Input } from 'ui/Input/Input';
import { z } from 'zod';
import { ALGOLIA_SEARCH_NAME } from '../../../../constants/algolia';
import { AlgoliaAutocomplete, AlgoliaAutocompleteState } from '../../../../types/Algolia';

const DEFAULT_INTERVAL = 5000;

const formSchema = z.object({
  [ALGOLIA_SEARCH_NAME]: z.string(),
});

type FormValues = z.infer<typeof formSchema>;

interface AutocompleteSearchInputProps {
  autocomplete: AlgoliaAutocomplete;
  autocompleteState?: AlgoliaAutocompleteState;
  inputRef: RefObject<HTMLInputElement>;
  showPanel: boolean;
}

const AutocompleteSearchInput = forwardRef(
  (
    { autocomplete, autocompleteState, inputRef, showPanel }: AutocompleteSearchInputProps,
    ref: Ref<HTMLFormElement>,
  ) => {
    const { locale } = useRouter();
    const { formatMessage } = useIntl();
    const hasValue = autocompleteState?.query || inputRef.current?.value;

    const defaultLabel = formatMessage({
      id: 'header_search_placeholder',
    });

    const [label, setLabel] = useState(defaultLabel);

    const { data: searchSuggestionsData } = useSearchSuggestions();
    const interval = searchSuggestionsData?.interval ?? 0;

    const [suggestionTypeWriter] = useTypewriter({
      delaySpeed: interval * 1000 || DEFAULT_INTERVAL,
      loop: false,
      words: searchSuggestionsData?.suggestions?.length ? searchSuggestionsData?.suggestions : [''],
    });

    const resetAutoComplete = () => {
      autocomplete.setQuery('');
      autocomplete.setIsOpen(false);
    };

    const form = useForm<FormValues>({
      defaultValues: {
        [ALGOLIA_SEARCH_NAME]:
          autocompleteState?.completion ||
          autocompleteState?.query ||
          autocomplete.getInputProps({
            inputElement: null,
          }).value,
      },
    });

    useEffect(() => {
      if (searchSuggestionsData?.suggestions?.length && suggestionTypeWriter) {
        setLabel(`${defaultLabel} ${suggestionTypeWriter}`);
      }
    }, [searchSuggestionsData, suggestionTypeWriter, locale]);

    useEffect(() => {
      resetAutoComplete();
      setLabel(defaultLabel);
    }, [locale]);

    return (
      <Form {...form}>
        <form
          ref={ref}
          {...autocomplete.getFormProps({ inputElement: inputRef.current })}
          className="flex w-full items-center gap-2"
        >
          <FormField
            control={form.control}
            name={ALGOLIA_SEARCH_NAME}
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel {...autocomplete.getLabelProps({})} />
                <FormControl>
                  <div className="relative">
                    <Input
                      {...field}
                      {...autocomplete.getInputProps({ inputElement: null })}
                      type="text"
                      placeholder={!hasValue && label ? label : ''}
                      className="pl-12"
                      ref={inputRef}
                    />
                    <IconButton
                      variant="ghost"
                      icon={{ name: hasValue ? 'xmark' : 'magnifying-glass', styling: 'fas' }}
                      iconClassName="size-6 text-primary-40"
                      className="absolute bottom-0 left-3 top-0 my-auto border-0"
                      aria-label={formatMessage({
                        id: 'algolia_search_name_label',
                      })}
                      onClick={(e) => {
                        e.preventDefault();
                        resetAutoComplete();
                      }}
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {showPanel && (
            <Button
              className="md:hidden"
              variant="ghost"
              onClick={(e) => {
                e.preventDefault();
                autocomplete.setIsOpen(false);
              }}
            >
              {formatMessage({ id: 'general-cancel' })}
            </Button>
          )}
        </form>
      </Form>
    );
  },
);

export default AutocompleteSearchInput;
